export function useUserLocation(myNavigator = navigator) {
  const getErrorMessage = (e) => {
    if (e?.code === 1) {
      return 'Permission denied getting your current location.';
    }

    if (e?.code === 2) {
      return 'Your current location is unavailable.';
    }

    if (e?.code === 3) {
      return 'Timeout getting your current location.';
    }

    return 'Unable to get your current location.';
  };

  const getGeoLocationPermissionStatus = async () => {
    if (typeof myNavigator?.permissions?.query !== 'function') {
      return undefined;
    }

    const result = await myNavigator.permissions.query({ name: 'geolocation' });

    return result.state;
  };

  /**
   * Return true if the user has granted permission to use their geolocation and false if they
   * haven't.
   */
  const hasGeoLocationPermissionBeenGranted = async () => {
    const state = await getGeoLocationPermissionStatus();
    return state === 'granted';
  };

  /**
   * Get the user's current location, using the cached location if valid and requested.
   */
  const getUserLocation = async (options = {}) => {
    return new Promise((resolve, reject) => {
      const defaultOptions = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 20 * 60 * 1000, // Cache location for 20 minutes (120,000 ms) by default
        ...options,
      };

      const handleSuccess = (position) => {
        if (position?.coords?.latitude && position?.coords?.longitude) {
          const { latitude, longitude } = position.coords;
          const location = [longitude, latitude];
          resolve(location);
        }

        resolve(undefined);
      };

      const handleError = (geolocationPositionError) => reject(geolocationPositionError);

      myNavigator.geolocation.getCurrentPosition(handleSuccess, handleError, defaultOptions);
    });
  };

  return {
    getErrorMessage,
    getGeoLocationPermissionStatus,
    getUserLocation,
    hasGeoLocationPermissionBeenGranted,
  };
}
